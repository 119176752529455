<template>
  <div class="vertical-menu">
    <div data-simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="list-unstyled" id="metismenu">
          <li>
            <router-link to="/Dashboard" class="waves-effect">
              <i class="ri-dashboard-line"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li class="menu-title" v-show="showApothecary || showPharmacy || showPharmacyStock || showSchedule">Administrator</li>
          <li v-show="SUPERIOR | SUPERADMIN">
            <a href="javascript: void(0);" class="has-arrow" aria-expanded="true">
              <i class="fas fa-user-circle"></i>
              <span>User</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/user">User</router-link></li>
              <li><router-link to="/user-type">Type</router-link></li>
            </ul>
          </li>
          <li v-show="showApothecary || showPharmacy || showPharmacyStock || showSchedule">
            <a href="javascript: void(0);" class="has-arrow" aria-expanded="true">
              <i class="ri-hospital-line"></i>
              <span>Pharmacy</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li v-show="showApothecary"><router-link to="/Apothecary">Apothecary</router-link></li>
              <li v-show="showPharmacy"><router-link to="/Pharmacy">Pharmacy</router-link></li>
              <li v-show="showPharmacyStock"><router-link to="/Pharmacy-Stock">Stock</router-link></li>
              <li v-show="showSchedule"><router-link to="/Schedule">Schedule</router-link></li>
            </ul>
          </li>
          <li v-show="SUPERIOR || SUPERADMIN">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-currency-line"></i>
              <span>Payment</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li>
                <router-link to="/Payment-Method">Payment Method</router-link>
              </li>
              <li>
                <router-link to="/Payment-Bank">Payment Bank</router-link>
              </li>
            </ul>
          </li>
          <li v-show="SUPERADMIN || SUPERIOR">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="mdi mdi-truck-fast-outline"></i>
              <span>Logistic</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/Logistic">Logistic</router-link></li>
              <li><router-link to="/Distribution">Distribution</router-link></li>
            </ul>
          </li>
          <li v-show="SUPERADMIN || SUPERIOR">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="fas fa-street-view"></i>
              <span>Address</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/Province">Province</router-link></li>
              <li>
                <router-link to="/City">City</router-link>
              </li>
              <li><router-link to="/District">District</router-link></li>
              <li><router-link to="/Village">Village</router-link></li>
            </ul>
          </li>
          <li v-show="this.role === 1 || this.role === 2">
            <router-link to="/doctor" class="waves-effect">
              <i class="fas fa-address-card"></i>
              <span>Doctor</span>
            </router-link>
          </li>
          <li v-show="SUPERIOR || SUPERADMIN || DOCTOR">
            <router-link to="/print" class="waves-effect">
              <i class="fas fa-print"></i>
              <span>Print Reset</span>
            </router-link>
          </li>

          <li class="menu-title">Daily Activity</li>
          <li>
            <router-link to="/Chat" class="waves-effect">
              <i class="ri-chat-1-line"></i>
              <span>Chat</span>
            </router-link>
          </li>
          <li v-show="SUPERIOR || SUPERADMIN || ADMIN">
            <router-link to="/Blacklist" class="waves-effect">
              <i class="mdi mdi-account-cancel"></i>
              <span>Blacklist</span>
            </router-link>
          </li>
          <li v-show="SUPERIOR || SUPERADMIN">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-store-2-line"></i>
              <span>Product</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/Product">Product</router-link></li>
              <li>
                <router-link to="/Category">Category</router-link>
              </li>
              <li><router-link to="/Type">Type</router-link></li>
            </ul>
          </li>
          <li v-show="SUPERIOR || SUPERADMIN">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-price-tag-3-fill"></i>
              <span>Promotion</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/Promotion">Promotion</router-link></li>
              <li><router-link to="/Banner">Banner</router-link></li>
            </ul>
          </li>

          <li v-show="!DOCTOR">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="mdi mdi-note-outline"></i>
              <span>Transaction</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/Transaction">Transaction</router-link></li>
              <li v-show="role < 3"><router-link to="/Member">Member</router-link></li>
            </ul>
          </li>
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="mdi mdi-receipt"></i>
              <span>E-Recipe</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/e-recipe">E-Recipe</router-link></li>
            </ul>
          </li>
          <li class="menu-title" v-show="SUPERIOR || SUPERADMIN">Mobile Content</li>
          <li v-show="SUPERIOR || SUPERADMIN">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-notification-line"></i>
              <span>Notification</span>
            </a>
            <ul v-show="SUPERIOR || SUPERADMIN" class="sub-menu" aria-expanded="false">
              <li><router-link to="/Notif">Push Notification</router-link></li>
            </ul>
          </li>
          <li v-show="SUPERADMIN || SUPERIOR">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-profile-line"></i>
              <span>Information</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/History">History</router-link></li>
              <li><router-link to="/Article">Article</router-link></li>
              <li><router-link to="/Treatment">Treatment</router-link></li>
              <li><router-link to="/Technology">Technology</router-link></li>
              <li><router-link to="/Clinic">Clinic</router-link></li>
            </ul>
          </li>
          <li class="menu-title" v-show="SUPERADMIN || SUPERIOR">Rep  ort</li>
          <li v-show="SUPERADMIN || SUPERIOR">
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="ri-file-chart-line"></i>
              <span>Report</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li><router-link to="/ReportBank">Transaction Bank</router-link></li>
              <li><router-link to="/ReportMember">Transaction Member</router-link></li>
              <li><router-link to="/ReportCourier">Courier</router-link></li>
              <li><router-link to="/ReportProduct">Product</router-link></li>
              <li><router-link to="/ReportPromotion">Promotion</router-link></li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
import { getRole } from '@/services/services';
import MetisMenu from 'metismenujs';
export default {
  data() {
    return {
      role: 0,
      SUPERIOR: undefined,
      SUPERADMIN: undefined,
      ADMIN: undefined,
      DOCTOR: undefined,
      PHARMACY: undefined,
    }
  },
  computed: {
  showApothecary() {
    return this.SUPERIOR || this.SUPERADMIN;
  },
  showPharmacy() {
    return this.SUPERIOR || this.SUPERADMIN;
  },
  showPharmacyStock() {
    return this.SUPERIOR || this.SUPERADMIN || this.PHARMACY;
  },
  showSchedule() {
    return this.SUPERIOR || this.SUPERADMIN;
  }
},
  mounted() {
    new MetisMenu("#metismenu");
    this.role = getRole();
    this.SUPERIOR = this.role === 1
    this.SUPERADMIN = this.role === 2
    this.ADMIN = this.role === 3
    this.DOCTOR = this.role === 4
    this.PHARMACY = this.role === 5
  },
};
</script>

<style scoped>
.vertical-menu::-webkit-scrollbar {
  display: none;

}
</style>
