import axios from 'axios';
import { generateClientSignature } from "./authentication"
import { callJWTAxios } from "./jwtService"
import { 
    encryptWithAES, 
    decryptWithAES    
} from "./crypto"
import { getCred } from './services';
import { deleteCookies } from './cookieHandler';

let url = process.env.VUE_APP_URL
const level = process.env.VUE_APP_LEVEL
const cred = getCred()
let jwtResponse

const headers = {
    'Content-Type': 'multipart/form-data',
    'Dbcode': 'benings_devel',
    'Credentials': cred
}

export async function callAPIAxios(request, files, prcCode) {
    const clientSignature = generateClientSignature(request.clientId)
    
    const formData = new FormData();

    if (request.type == "product") {
        for (var i = 0; i < request.file.length; i++) {
            let file = request.file[i];
            formData.append(`file`, file);
        }
    } else {
        formData.append(`file`, request.file);
    }
    
    if(level == "production"){
        await callJWTAxios().then(response => {
            jwtResponse = response
        })
        
        if (jwtResponse != null){
            if (jwtResponse.outError != 0){
                return
            }
            Object.assign(request, { "clientSignature": clientSignature })
            request = encryptWithAES(JSON.stringify(request))
            Object.assign(headers, { "Authorization": 'Bearer '+jwtResponse.outToken })
        }else{
            console.log("Unautorized!")
            return
        }
    }else{
        request = JSON.stringify(request)
    }

    Object.assign(headers, { "Procedure-Code": prcCode })

    formData.append(`body`, request);

    const response = await axios.post(url, formData, {headers: headers})
    .then(response => {
        return response
    })
    .catch(error => {
        return error.response
    })
    if(response.status === 401){
        deleteCookies("_cr");
        return window.location.href = "/";
    }
    if(level == "production"){
        response.data = JSON.parse(decryptWithAES(response.data))
    }    
    return response
}

export async function callAPIAuth(request, prcCode) {
    const clientSignature = generateClientSignature(request.clientId)
    
    const formData = new FormData();

    Object.assign(headers, { "Procedure-Code": prcCode })
    
    if(level == "production"){
        await callJWTAxios().then(response => {
            jwtResponse = response
        })
        
        if (jwtResponse != null){
            if (jwtResponse.outError != 0){
                return
            }
            Object.assign(request, { "clientSignature": clientSignature })
            request = encryptWithAES(JSON.stringify(request))
            Object.assign(headers, { "Authorization": 'Bearer '+jwtResponse.outToken })
        }else{
            console.log("Unautorized!")
            return
        }
    }else{
        request = JSON.stringify(request)
    }

    formData.append(`body`, request);
    const response = await axios.post(url, formData, {headers: headers})
    .then(response => {
        return response
    })
    .catch(error => {
        return error.response
    })
    if(response.status === 401){
        return response
    }
    if(level == "production"){
        response.data = JSON.parse(decryptWithAES(response.data))
    } 

    return response
}

