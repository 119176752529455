<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/Dashboard" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm-benings.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img
                src="assets/images/logo-Putih(300ppi).png"
                alt=""
                height="40"
              />
            </span>
          </a>
        </div>

        <button
          @click="toggleMenu()"
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-search-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ri-search-line"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            @click="initFullScreen()"
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>

        <div class="dropdown d-inline-block user-dropdown">
          <button
            type="button"
            class="btn header-item waves-effect"
            id="page-header-user-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="rounded-circle header-profile-user"
              src="../assets/logo.png"
              alt="Header Avatar"
            />
            <!-- <span class="d-none d-xl-inline-block ml-1">{{ uname }}</span> -->
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <!-- item-->
              <a class="dropdown-item" href="#"><i class="ri-user-line align-middle mr-1"></i> {{ uname }}</a>
              <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript:void(0);" @click="logout"
              ><i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
              Logout</a>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModalAuth
      :contentProp="contentModal"
      @submitConfirm="submitLogout"
    />
    <AlertModalAuth
      :contentProp="contentModal"
      :titleProp="titleModal"
      :varianProps="variant"
      @confirmAlert="confirmAlert"
    />
  </header>
</template>

<script>
import router from "@/router";
import { deleteCookies } from "@/services/cookieHandler";
import { callAPIAuth } from "@/services/serviceAxios";
import { getTokens, getUserName } from "@/services/services";
import ConfirmModalAuth from "@/components/ConfirmModalAuth.vue";
import AlertModalAuth from "./AlertModalAuth.vue";
export default {
  name: "NavBar",
  components: {
    ConfirmModalAuth,
    AlertModalAuth
  },
  data() {
    return {
      isMenuCondensed: false,
      uname:"",
      token: null,
      contentModal: "",
      titleModal: "",
      variant: "",
    };
  },
  mounted(){
    this.uname = getUserName();
    this.token = getTokens();
  },
  methods: {
    logout() {
      this.contentModal = "Are you sure want to logout?";
      this.$bvModal.show("ConfirmModalAuth");
    },
    async submitLogout() {
      const request = {
        clientId: "benings",
        token: this.token
      }
      await callAPIAuth(request, "USER ADMIN LOGOUT")
      .then(resp => {        
        if(resp != null){
          if(resp.status != 200){
            this.titleModal = "Failed";
            this.contentModal = "Failed to logout";
            this.variant = "danger";
          }else{
            this.titleModal = "Success";
            this.contentModal = "Logout success";
            this.variant = "success";
            setTimeout(() => {
            }, 2000);
          }
          this.$bvModal.show("AlertModalAuth");
        }
      })
    },
    confirmAlert(){
      if(this.titleModal == "Success"){
        deleteCookies("_cr");
        window.location = "/";
      }
      this.$bvModal.hide("AlertModalAuth");
    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
  },
};
</script>
