<template>
  <div>
    <b-modal 
      no-close-on-backdrop 
      id="ConfirmModalAuth" 
      title="Confirmation"
      @ok="submitConfirm()"
      :header-bg-variant="headerBgVariant"
      hide-header-close
      centered
    >
      <template #modal-title>
        <span class="text-light">
          Confirmation
        </span>
      </template>
      <div class="container-fluid">
        <div class="row text-center my-4">
          <b-icon icon="question-circle" variant="warning" font-scale="5"></b-icon>
        </div>
        <div class="row text-center">
          <p>{{contentProp}}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  export default{
    name: "ConfirmModalAuth",
    props: ['contentProp'],
    data(){
      return{
        showConfirm: false,
        variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
        headerBgVariant: 'warning',
      }
    },
    methods: {
      submitConfirm(){
        this.$emit('submitConfirm', true)
      }
    }
  }
</script>