import { getCred, getRole } from "@/services/services";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/login",
    name: "LoginView",
    component: () => import("../views/Auth/Login.vue")
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: true
    }
  },  
  {
    path: "*",
    name: "FourOFour",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/city",
    name: "CityView",
    component: () => import("../views/Pages/Address/City/City.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/village",
    name: "VillageView",
    component: () => import("../views/Pages/Address/Village/Village.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/district",
    name: "DistrictView",
    component: () => import("../views/Pages/Address/District/District.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/province",
    name: "ProvinceView",
    component: () => import("../views/Pages/Address/Province/Province.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/print",
    name: "PrintView",
    component: () => import("../views/Pages/Settings/Print/Print.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/user",
    name: "UserView",
    component: () => import("../views/Pages/Administrator/User/User.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/user-type",
    name: "UserTypeView",
    component: () => import("../views/Pages/Administrator/AdminType/AdminType.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/doctor",
    name: "DoctorView",
    component: () => import("../views/Pages/Administrator/Doctor/Doctor.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/Pages/Products/Product/Product.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/bundling",
    name: "bundling",
    component: () => import("../views/Pages/Products/Bundling/Bundling.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/category",
    name: "category",
    component: () => import("../views/Pages/Products/Category/Category.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/type",
    name: "Type",
    component: () => import("../views/Pages/Products/Type/Type.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Apothecary",
    name: "Apothecary",
    component: () => import("../views/Pages/Pharmacy/Apoteker/Apoteker.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Pharmacy",
    name: "Pharmacy",
    component: () => import("../views/Pages/Pharmacy/Pharmacy/Pharmacy.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Pharmacy-Stock",
    name: "Stock",
    component: () => import("../views/Pages/Pharmacy/PharmacyStock/PharmacyStock.vue"),
    meta: {
      superAdminAllow:true,
      requireAuth: true
    }
  },
  {
    path: "/Schedule",
    name: "Schedule",
    component: () => import("../views/Pages/Pharmacy/Schedule/Schedule.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Distribution",
    name: "Distribution",
    component: () => import("../views/Pages/Logistic/Distribution/Distribution.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Banner",
    name: "BannerView",
    component: () => import("../views/Pages/Banner/Banner.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Logistic",
    name: "LogisticView",
    component: () => import("../views/Pages/Logistic/Logistic.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Promotion",
    name: "PromotionView",
    component: () => import("../views/Pages/Promotion/Promotion/Promotion.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Promo-User",
    name: "PromoUserView",
    component: () => import("../views/Pages/Promotion/Promo_User/PromoUser.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Notif",
    name: "NotificationView",
    component: () => import("../views/Pages/Notification/Notification.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/History",
    name: "HistoryView",
    component: () => import("../views/Pages/Information/History/History.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Article",
    name: "ArticleView",
    component: () => import("../views/Pages/Information/Article/Article.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false,
    }
  },
  {
    path: "/Clinic",
    name: "ClinicView",
    component: () => import("../views/Pages/Information/clinic/Clinic.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Technology",
    name: "TechnologyView",
    component: () => import("../views/Pages/Information/Technology/Technology.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  
  {
    path: "/Treatment",
    name: "TreatmentView",
    component: () => import("../views/Pages/Information/Treatment/Treatment.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  
  {
    path: "/Payment-Bank",
    name: "PaymentBankView",
    component: () => import("../views/Pages/Payment/Payment_Bank/PaymentBank.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  
  {
    path: "/Payment-Method",
    name: "PaymentMethodView",
    component: () => import("../views/Pages/Payment/Payment_Method/PaymentMethod.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow: false
    }
  },
  {
    path: "/Transaction",
    name: "TransactionView",
    component: () => import("../views/Pages/Transaction/Transaction.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path: "/Member",
    name: "MemberView",
    component: () => import("../views/Pages/Transaction/Member/Member.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path: "/e-recipe",
    name: "EreceiptView",
    component: () => import("../views/Pages/Ereceipt/Ereceipt.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path: "/Chat",
    name: "App-Chat",
    component: () => import("../views/Pages/Chat/Chat.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true,
      superior: true
    }
  },
  {
    path: "/Blacklist",
    name: "BlackListMember",
    component: () => import("../views/Pages/Blacklist/BlackListMember.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path: "/ReportBank",
    name: "ReportBank",
    component: () => import("../views/Pages/Report/Bank/BankReport.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path: "/ReportMember",
    name: "ReportMember",
    component: () => import("../views/Pages/Report/Member/MemberReport.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path: "/ReportCourier",
    name: "ReportCourier",
    component: () => import("../views/Pages/Report/Courier/CourierReport.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true,
      superior : true
    }
  },
  {
    path: "/ReportProduct",
    name: "ReportProduct",
    component: () => import("../views/Pages/Report/Product/ProductReport.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
  {
    path:"/ReportPromotion",
    name:"ReportPromotion",
    component:()=>import("../views/Pages/Report/Promotion/PromotionReport.vue"),
    meta: {
      superAdminAllow:true,
      adminAllow : true
    }
  },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


router.beforeEach((to, from, next) => { 
  const cred = getCred();
  const role = getRole();

  const superAdminAllow = to.matched.some(record => record.meta.superAdminAllow);
  const adminAllow = to.matched.some(record => record.meta.adminAllow);
  const superiorAllow = to.matched.some(record => record.meta.superior); // Check for superior role


  const roleAuth = 
    ((role === 1 && to.path !== "/ErrorNotFound") && !superAdminAllow && !superiorAllow) || 
    ((role === 3 && to.path !== "/ErrorNotFound") && !adminAllow) ||
    ((role === 2 && to.path !== "/ErrorNotFound") && !superAdminAllow);

  if (!cred) {
    if (to.path !== "/login") {
      return next("/login");
    }
  } else {
    if (to.path === "/login" || to.path === "/") {
      return next("/Dashboard");
    }
    if (roleAuth) {
      return next("/ErrorNotFound");
    }
    return next();
  }
});



export default router;