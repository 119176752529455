import axios from 'axios';
import { decryptWithAESJWT} from "./crypto"

export async function callJWTAxios() {
    return await axios.get(process.env.VUE_APP_JWT_URL+"/api/v2/getToken", {
        headers: {
            'Authorization': 'Basic '+process.env.VUE_APP_AUTH_JWT,
            'Content-Type': 'application/json'
        }
    }).then(function(resp){
        let response = JSON.parse(decryptWithAESJWT(resp.data.data, process.env.VUE_APP_JWT_KEY))
        return response
    })
}