import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import JlDatatable from 'jl-datatable'
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueChatScroll from 'vue-chat-scroll';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueToasted from 'vue-toasted';


const print_option = {
    name: 'Benings_Cnote_Print',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        // 'https://unpkg.com/kidlat-css/css/kidlat.css',
        // test,    
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}


Vue.use(VueHtmlToPaper, print_option);
Vue.use(VueChatScroll)

Vue.config.productionTip = false;

Vue.use(VueToasted, {
    iconPack: 'custom-class' // set your iconPack, defaults to material. material|fontawesome|custom-class
});
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('jl-datatable', JlDatatable);

Vue.config.productionTip = false

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");



