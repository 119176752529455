<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />
    <div class="main-content">
      <router-view ref="view"/>
      <Footer />
    </div>
    <!-- end main content-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
import { SOCKET_INTERNAL_PRIVATE_MESSAGE, SOCKET_PRIVATE_MESSAGE } from "@/utils/constant";
import socket from "@/socket";
import { getUserId } from "@/services/services";

export default {
  name: "HomeView",
  components: {
    NavBar,
    SideBar,
    Footer,
  },
  data(){
    return {      
      adminId: getUserId(),
      path: '',
    }
  },
  beforeMount(){    
    console.log("Ini halaman HomeView")
    this.onDestroy()
    this.getChatNotif()
  },
  methods: {
    onDestroy(){
      socket.off(SOCKET_PRIVATE_MESSAGE)
      socket.off(SOCKET_INTERNAL_PRIVATE_MESSAGE)
    },
    getChatNotif(){
      console.log("eksekusi!!")    
      socket.on(SOCKET_PRIVATE_MESSAGE, (data) => {
        if(data.sender_id != this.adminId && this.path != "/Chat"){
          this.$toasted.show(`new external message`, {
            theme: "bubble",
            icon : {
              name : 'mdi mdi-chat'
            },
            keepOnHover: true,
            duration: 5000,
            action:[
              {
                text: "Open",
                onClick: (e, toastObject) => {
                  this.$router.push('/Chat'); 
                  toastObject.goAway(0);
                },
              },
              {
                icon : 'mdi mdi-close',
                class: 'text-danger',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              }
            ]
          })
        }
      });
      socket.on(SOCKET_INTERNAL_PRIVATE_MESSAGE, (data) => {
        if(data.sender_id != this.adminId && this.path != "/Chat"){
          this.$toasted.show(`new internal message`, {
            theme: "outline",
            icon : {
              name : 'mdi mdi-chat'
            },
            keepOnHover: true,
            duration: 5000,
            action:[
              {
                text: "Open",
                class: 'text-md',
                onClick: (e, toastObject) => {
                  this.$router.push('/Chat');
                  toastObject.goAway(0);
                },
              },
              {
                icon : 'mdi mdi-close',
                class: 'text-danger',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              }
            ]
          })
        }
      });      
    },
  },
  watch: {
    $route(to){
      console.log("to", to.path)
      this.path = to.path
    }
  }
}
</script>