<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-4">
          <img style="cursor:pointer;width:150px" src="https://seal.godaddy.com/images/3/en/siteseal_gd_3_h_l_m.gif" onclick="verifySeal();" alt="SSL site seal - click to verify"> 
        </div>
        <div class="col-sm-4 text-center">
           ©Benings Admin. 
        </div>
        <div class="col-sm-4">
          <div class="text-sm-right d-none d-sm-block">
            Crafted with <i class="mdi mdi-heart text-danger"></i
            ><a href="https:/flowmotion.co.id/" target="_blank">
              by FlowMotion Developer
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterView",
};
</script>
