import { getCookies } from "./cookieHandler";
import { decryptWithAES } from "./crypto";

function getCred(){    
    const cred = getCookies("_cr")
    return cred 
}

const cred = getCred();
const signature = cred ? JSON.parse(decryptWithAES(cred)) : null;

function getRole(){   
    if(signature){          
        const role = signature.user_type;
        return role;
    }
}
function getTokens(){ 
    if(signature){
        const tokens = signature.token;
        return tokens;
    }
}
function getUserName(){  
    if(signature){       
        const userName = signature.user_name;
        return userName;
    }
}

function getUserId(){  
    if(signature){       
        const userId = signature.user_id;
        return userId;
    }
}

export {
    getCred,
    getRole,
    getTokens,
    getUserName,
    getUserId
}

