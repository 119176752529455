import socket from "../socket";
import {
  SOCKET_ON_CONNECT,
  SOCKET_SUCCESS_CONNECT,
  SOCKET_ON_CONNECT_ERROR,
  SOCKET_ON_DISCONNECT,
  SOCKET_NEW_INTERACTIVE_CHAT,
  SOCKET_NEW_INTERNAL_INTERACTIVE_CHAT,
  SOCKET_INTERNAL_PRIVATE_MESSAGE,
  SOCKET_PRIVATE_MESSAGE,
  SOCKET_IS_INTERNAL_TYPING,
  SOCKET_IS_TYPING,
  SOCKET_INTERACTIVE_CHAT
} from "./constant";

const allSocketEvents = [
  SOCKET_ON_CONNECT,
  SOCKET_SUCCESS_CONNECT,
  SOCKET_ON_CONNECT_ERROR,
  SOCKET_ON_DISCONNECT,
  SOCKET_NEW_INTERACTIVE_CHAT,
  SOCKET_NEW_INTERNAL_INTERACTIVE_CHAT,
  SOCKET_NEW_INTERACTIVE_CHAT,
  SOCKET_INTERNAL_PRIVATE_MESSAGE,
  SOCKET_PRIVATE_MESSAGE,
  SOCKET_IS_INTERNAL_TYPING,
  SOCKET_IS_TYPING,
  SOCKET_INTERACTIVE_CHAT
];

export function socketOnConnect(tokens) {
  console.log("Kesenggol nih socketOnConnect");
  socket.disconnect();
  socket.auth = { token: tokens, level: "admin" };
  socket.connect();
  // Common
  socket.on(SOCKET_ON_CONNECT, () => {
    console.log("Successfully Connected! by socketOnConnect");
  });
  socket.on(SOCKET_SUCCESS_CONNECT, (val) => {
    console.log("Sukses konek by socketOnConnect", val);
    console.log("sesudah konek: ", typeof socket, socket);
  });
  socket.on(SOCKET_ON_DISCONNECT, () => {
    console.log("Successfully Disconnected! by socketOnConnect");
  });
  socket.on(SOCKET_ON_CONNECT_ERROR, (err) => {
    console.log(err);
  });
}

export function destroyNotifChat(){
  socket.off(SOCKET_PRIVATE_MESSAGE)
  socket.off(SOCKET_INTERNAL_PRIVATE_MESSAGE)
}

export function destroySocket() {
  console.log("kena destroy dari utils!");
  // Common
  allSocketEvents.forEach((event) => {
    socket.off(event);
  });
}
