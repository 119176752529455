const CryptoJS = require('crypto-js')

const SECRET = process.env.VUE_APP_SECREY_ENC;

const encryptWithAES = (text) => {
    const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(text),
        CryptoJS.enc.Utf8.parse(SECRET), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    let hexString = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    return hexString;
};

const decryptWithAES = (text) => {
    var encryptedHex = CryptoJS.enc.Hex.parse(text);
    var encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
    const decrypted = CryptoJS.AES.decrypt(
        encryptedBase64,
        CryptoJS.enc.Utf8.parse(SECRET), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    let hexString = decrypted.toString(CryptoJS.enc.Utf8);
    return hexString;
};

const decryptWithAESJWT = (text, secretJWT) => {
    var encryptedHex = CryptoJS.enc.Hex.parse(text);
    var encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex);
    const decrypted = CryptoJS.AES.decrypt(
        encryptedBase64,
        CryptoJS.enc.Utf8.parse(secretJWT), { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    let hexString = decrypted.toString(CryptoJS.enc.Utf8);
    return hexString;
};


export {
    encryptWithAES,
    decryptWithAES,
    decryptWithAESJWT
}