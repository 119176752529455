export const CLIENT_ID = 'benings';

//data lists
export const GET_LIST_ADMIN = "GET LIST ADMIN";
export const GET_LIST_ADMIN_TYPE = "GET LIST ADMIN TYPE";
export const GET_LIST_APOTECHARIST = "GET LIST APOTHECARIST";
export const GET_LIST_PHARMACY = "GET LIST PHARMACY";
export const GET_LIST_PHARMACY_STOCK = "GET LIST PHARMACY STOCK";
export const GET_LIST_PHARMACY_SCHEDULE = "GET LIST PHARMACY SCHEDULE";
export const GET_LIST_PAYMENT_METHOD = "GET LIST PAYMENT METHOD";
export const GET_LIST_BANK = 'GET LIST BANK';
export const GET_LIST_COURIER = 'GET LIST COURIER';
export const GET_LIST_COURIER_SERVICE = 'GET LIST COURIER SERVICE';
export const GET_LIST_PHARMACY_DISTRIBUTIONS = "GET LIST PHARMACY DISTRIBUTIONS";
export const GET_LIST_PROVINCE = "GET LIST PROVINCE";
export const GET_LIST_CITY = "GET LIST CITY";
export const GET_LIST_DISTRICT = "GET LIST DISTRICT";
export const GET_LIST_VILLAGE = "GET LIST VILLAGE";
export const GET_LIST_DOCTOR = "GET LIST DOCTOR";
export const GET_LIST_RECEIPT_DETAIL = "GET LIST RECEIPT DETAIL";
export const GET_PHARMACY_STOCK_LOGS = "GET PHARMACY STOCK LOGS";
export const GET_LIST_PRODUCTS = "GET LIST PRODUCTS";
export const GET_LIST_PRODUCT_ATTRIBUTE = "GET LIST PRODUCT ATTRIBUTE";
export const GET_LIST_BUNDLING_HEADER = "GET LIST BUNDLING HEADER";
export const GET_LIST_BUNDLING_DETAIL = "GET LIST BUNDLING DETAIL";
export const GET_LIST_CATEGORIES = "GET LIST CATEGORIES";
export const GET_LIST_PRODUCT_CATEGORIES = "GET LIST PRODUCT CATEGORY";
export const GET_LIST_TYPE = "GET LIST TYPE";
export const GET_LIST_VOUCHER = "GET LIST VOUCHER";
export const GET_LIST_VOUCHER_PRODUCT = "GET LIST VOUCHER PRODUCT";
export const GET_LIST_VOUCHER_COURIER = "GET LIST VOUCHER COURIER";
export const GET_LIST_ORDER = "GET LIST ORDER";
export const GET_lIST_ORDER_HEADER="GET LIST ORDER HEADER";
export const GET_lIST_MEMBER="GET LIST MEMBER";
export const GET_lIST_RECEIPT_HEADER="GET LIST RECEIPT HEADER";
export const GET_lIST_RECEIPT_DETAIL="GET LIST RECEIPT DETAIL";
export const GET_lIST_NOTIFICATION="GET LIST NOTIFICATION";
export const GET_lIST_HISTORY="GET LIST HISTORY";
export const GET_lIST_ARTICLE="GET LIST ARTICLE";
export const GET_lIST_TREATMENT="GET LIST TREATMENT";
export const GET_lIST_TECHNOLOGY="GET LIST TECHNOLOGY";
export const GET_LIST_CLINIC = "GET LIST CLINIC";

//lov
export const GET_LOV_APOTHECARY_ON_ADMIN = "GET LOV APOTHECARY ON ADMIN";
export const GET_LOV_DOCTOR_ON_ADMIN = "GET LOV DOCTOR ON ADMIN";
export const GET_LOV_ARTICLE_SEQUENCE = "GET LOV ARTICLE SEQUENCE";

//Validate Master Code
export const VALIDATE_MASTER_CODE = "VALIDATE MASTER CODE";


//SOCKET
//socket on
export const SOCKET_ON_CONNECT = "connect";
export const SOCKET_ON_DISCONNECT = "disconnect";
export const SOCKET_SUCCESS_CONNECT = "success_connect";
export const SOCKET_ON_CONNECT_ERROR = "connect_error";
export const SOCKET_NEW_INTERNAL_INTERACTIVE_CHAT = "new_internal_interactive_chat"; // socket on new internal interactive chats
export const SOCKET_NEW_INTERACTIVE_CHAT = "new_interactive_chat"; // socket on new interactive chat from members
export const SOCKET_INTERNAL_PRIVATE_MESSAGE ="internal_private_message" // socket on internal message for chat window
export const SOCKET_PRIVATE_MESSAGE = "private_message"; // socket on member message for chat window
export const SOCKET_IS_INTERNAL_TYPING ="internal_is_typing" // socket on is internal typing
export const SOCKET_IS_TYPING = "is_typing"; // socket on member is typing
export const SOCKET_INTERACTIVE_CHAT = "interactive_chat"; // socket on interactive chat member
export const INTERNAL_DELETED_MESSAGE = 'internal_deleted_message' // socket on deleted message internal

//socket emit
export const SOCKET_GET_USER_ROOM = "get_user_room"; // emit for get internal users room
export const SOCKET_CREATE_PERSONAL_ROOM = "create_personal_room"; // emit for create internal personal room
export const SOCKET_GET_ROOM_CONTENT ="get_room_content" // emit for get internal room content history
export const SOCKET_GET_INTERACTIVE_CHAT = "get_interactive_chat"; // emit for get member interactive chat (get external room)
export const SOCKET_GET_CHAT_BY_SESSION = "get_chat_by_session"; // emit for get chat by session with member history
export const SOCKET_GET_MEMBER_HISTORY = "get_member_history"; // emit for get member history all
export const SOCKET_INTERNAL_TYPING ="internal_on_typing" // emit for internal on typing
export const SOCKET_ON_TYPING ="on_typing" // emit for member on typing
export const SOCKET_INTERNAL_SEND_MESSAGE ="internal_send_message" // emit for send message to internal
export const SOCKET_SEND_MESSAGE = "send_message"; // emit for send message to member
export const SOCKET_ADMIN_LOCK_SESSION = "admin_lock_session"; // emit for admin lock session with member (prosess member)
export const SOCKET_ADMIN_END_SESSION = "admin_end_session"; // emit for admin end session with member (end chat member)
export const SOCKET_HIDE_ROOM = 'hide_room' // emit for hide internal room
export const ADMIN_CREATE_SESSION = 'admin_create_session' // emit for create session with member
export const SOCKET_ADMIN_DELETE_MESSAGE = "admin_delete_message"; // emit for deleted message admin to member
export const SOCKET_INTERNAL_DELETE_MESSAGE = "internal_delete_message"; // emit for deleted message internal

//socket old
// export const SOCKET_NEW_IDLE_CHAT = "new_idle_chat";
