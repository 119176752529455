<template>
  <div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="authentication-page-content p-4 d-flex align-items-center">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a href="index.html" class="logo"
                        ><img
                          src="assets/images/Beningslogo.jpg"
                          height="180"
                          alt="logo"
                      /></a>
                    </div>

                    <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                    <p class="text-muted">Sign in to continue to Benings.</p>
                  </div>

                  <div class="p-2 mt-5">
                    <form class="form-horizontal" >
                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-user-2-line auti-custom-input-icon"></i>
                        <label for="username">Username</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter username"
                          v-model="user"
                          required
                        />
                      </div>

                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Password</label>
                        <input
                          v-model="pass"
                          :type="passwordVisible ? 'text' : 'password'"
                          class="form-control"
                          placeholder="Enter password"
                          required
                        />
                        <i 
                          :class="passwordVisible ? 'ri-eye-line password-visible-btn' : 'ri-eye-off-line password-visible-btn'" 
                          @click="passwordVisible = !passwordVisible"
                          :title="passwordVisible ? 'Hide password' : 'Show password'"
                          :style="passwordVisible ? 'color: #555;' : 'color: #ccc;'"
                        ></i>
                      </div>

                      <div class="custom-control custom-checkbox" hidden>
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline"
                          >Remember me</label
                        >
                      </div>

                      <div class="mt-4 text-center">
                        <button
                          @click.prevent="loginHandler"
                          class="btn btn-secondary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div class="mt-4 text-center">
                        <a href="auth-recoverpw.html" class="text-muted"
                          ><i class="mdi mdi-lock mr-1"></i> Forgot your
                          password?</a
                        >
                      </div>
                    </form>
                  </div>
                  <p style="color: red; font-size: 15px">{{ message }}</p>
                  <div class="mt-5 text-center">
                    <p>
                      © 2021 Benings. Crafted with
                      <i class="mdi mdi-heart text-danger"></i> by Flowmotion
                      Developer
                    </p>
                    <p>
                      <img style="cursor:pointer;width:150px" src="https://seal.godaddy.com/images/3/en/siteseal_gd_3_h_l_m.gif" onclick="verifySeal();" alt="SSL site seal - click to verify">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="authentication-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
    <ConfirmModalAuth
      :contentProp="contentModal"
      @submitConfirm="loginIn"
    />
    <!-- <AlertModalAuth
      :contentProp="contentModal"
      :titleProp="titleModal"
      :varianProps="variant"
      @confirmAlert="confirmAlert"
    /> -->
  </div>
  
</template>

<script>
import ConfirmModalAuth from "@/components/ConfirmModalAuth.vue";
import { setCookies } from "@/services/cookieHandler";
import { decryptWithAES } from "@/services/crypto";
import { callAPIAuth } from "@/services/serviceAxios.js";
var response
export default {
  name: "LoginView",
  components: {
    ConfirmModalAuth,
  },
  data() {
    return {
      user: "",
      pass: "",
      message: "",
      request: {},
      contentModal: "",
      titleModal: "",
      variant: "",
      passwordVisible: false,
    };
  },
  methods: {
    loginHandler() {      
      this.request = {
        clientId: "benings",
        userName: this.user,
        userPassword: this.pass,
      };
      try{
        callAPIAuth(this.request, 'USER ADMIN CHECK LOGIN')
        .then(resp => {
          if (resp != null){
            console.log("resp", resp)
            const response = resp.data
            if(response.outError === 0){
              this.loginIn();
            }else if(response.outError === 3){
              this.contentModal = response.outMessage;
              this.$bvModal.show('ConfirmModalAuth')
            }else{
              this.message = "*" + response.outMessage;
            }
          }else{
            console.log("error")
          }
        })
        .catch(e => {
          console.log(e)
          const resp = e.response.data;
          if(resp.outError == 3){            
            this.contentModal = resp.outMessage;
            this.overwriteHandler();
          }
        })
      }catch(e){
        console.log(e)
      }
    },
    loginIn(){
      callAPIAuth(this.request, 'USER ADMIN LOGIN').then(resp => {
        if (resp != null){
          response = resp.data;
          if (response.outError > 0) {
            this.message = "*" + response.outMessage;
          } else {
            const cred = JSON.parse(decryptWithAES(response.credentials));
            const date = new Date(cred.expired_date);
            const expired_date = date.toUTCString();
            setCookies("_cr", response.credentials, expired_date);
            location.href="/Dashboard";
          }
        }
      });
    }
  },
};
</script>

<style>
.password-visible-btn{
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}
</style>