
const setCookies = (name, value, expires) => {
  document.cookie = `${name}=${value};expires=${expires};path=/`;
}

const getCookies = (name) => {
  const cookie = document.cookie.split(';').find(cookie => cookie.includes(name));
  return cookie ? cookie.split('=')[1] : null;
}

const deleteCookies = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export {
  setCookies,
  getCookies,
  deleteCookies
}