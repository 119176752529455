import { io } from "socket.io-client";

const URL = process.env.VUE_APP_CHAT_URL;
const socket = io(
    URL, 
    { 
        autoConnect: false, 
        secure: true, 
        rejectUnauthorized: false, 
        path: process.env.VUE_APP_CHAT_PATH, 
        transports: ["websocket"] 
    }
);

socket.onAny((event, ...args) => {
    console.log('test', event, args);
});

export default socket;