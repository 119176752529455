import md5 from "md5";

function GetDateFormat() {
    var dateObj = new Date();
    var month = (dateObj.getMonth() + 1).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    var hour = dateObj.getHours();
    hour = hour >= 10 ? hour : '0' + hour;
    var minute = dateObj.getMinutes();
    var minutes
    if (minute % 10 > 5) {
        minutes = "10"
    } else {
        minutes = "0"
    }
    minute = minute >= 10 ? minute : '0' + minute;

    return month + '' + day + '' + dateObj.getFullYear() + '' + hour + '' + minutes
}

function generateClientSignature(clientId) {
    const secretKey = process.env.VUE_APP_KEY
    var dateFormat = GetDateFormat();
    var clientSignature = md5(clientId + '' + dateFormat + '' + md5(secretKey))
    return clientSignature
}

export {
    generateClientSignature
}