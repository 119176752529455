<template>
  <div>    
    <b-modal no-close-on-backdrop 
      id="AlertModalAuth" 
      hide-footer
      :header-bg-variant="varianProps"
      hide-header-close
      centered
    >
      <template #modal-title>
        <span class="text-light">
          {{titleProp}}
        </span>
      </template>      
      <div class="container-fluid">
        <div class="row text-center my-4">            
          <b-icon v-if="titleProp == 'Failed'" icon="exclamation-circle" variant="danger" font-scale="5"></b-icon>
          <b-icon v-else icon="check-circle" variant="success" font-scale="5"></b-icon>
        </div>
        <div class="row text-center">
          <p>{{contentProp}}</p>
        </div>
        <div class="row text-center">            
          <b-button 
            class="mt-3" 
            block 
            @click="onClickClose"
          >
            Close
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>  
</template>

<script>
export default {
  name: "AlertModalAuth",
  props: ['contentProp', 'titleProp', 'varianProps'],
  data(){
    return{
      variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
    }
  },
  methods: {
    onClickClose(){
      this.$emit('confirmAlert', false)
    }
  }
}
</script>